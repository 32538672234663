module.exports = [{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Metallbau Service - E. Smolarczyk","short_name":"Smolarczyk","background_color":"#11335f","theme_color":"#11335f","start_url":"/","icon":"static/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5826929b2ae7c3dad1eac3f9c1861950"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
